import axios from 'axios';

const { config } = require('../../package.json');

export const IMAGE_ORIGIN = 'IMAGE_ORIGIN';
export const IMAGE_SECRET = 'IMAGE_SECRET';
export const IMAGE_ORIGIN_ADDED = 'IMAGE_ORIGIN_ADDED';
export const IMAGE_SECRET_ADDED = 'IMAGE_SECRET_ADDED';
export const IMAGE_EMBED_START = 'IMAGE_EMBED_START';
export const IMAGE_EMBED_END = 'IMAGE_EMBED_END';
export const IMAGE_EXTRACT_START = 'IMAGE_EXTRACT_START';
export const IMAGE_EXTRACT_END = 'IMAGE_EXTRACT_END';
export const IMAGE_EXTRACT_STATUS_UPDATE = 'IMAGE_EXTRACT_STATUS_UPDATE';

export const addOriginImage = imageEncoded => ({
  type: IMAGE_ORIGIN_ADDED,
  imageEncoded,
});

export const addSecretImage = imageEncoded => ({
  type: IMAGE_SECRET_ADDED,
  imageEncoded,
});

export const endEmbedImage = embeddedImage => ({
  type: IMAGE_EMBED_END,
  embeddedImage,
});

export const startExtractImage = markedImage => ({
  type: IMAGE_EXTRACT_START,
  markedImage,
});

export const endExtractImage = extractedImage => ({
  type: IMAGE_EXTRACT_END,
  extractedImage,
});

export const updateExtractStatus = status => ({
  type: IMAGE_EXTRACT_STATUS_UPDATE,
  status,
});

export const embedImage = () => (dispatch, getState) => {
  const { image: { imageOrigin, imageSecret } } = getState();
  axios({
    url: config.apiUrl,
    method: 'post',
    data: {
      query: `
        {
          embed(
            originImage: "${imageOrigin}",
            secretImage: "${imageSecret}"
          )
        }
      `,
    },
  }).then(result => {
    const { embeddedImage } = JSON.parse(result.data.data.embed).msg;
    console.log(embeddedImage);
    dispatch(endEmbedImage(embeddedImage));
  });
};

export const extractImage = markedImage => (dispatch, getState) => {
  dispatch(startExtractImage(markedImage));
  axios({
    url: config.apiUrl,
    method: 'post',
    data: {
      query: `
        {
          extract(
            markedImage: "${markedImage}",
          )
        }
      `,
    },
    onUploadProgress: event => {
      if (event.loaded === event.total) {
        dispatch(updateExtractStatus('正在解析'));
      } else {
        dispatch(updateExtractStatus(`正在上传
          ${Math.round(event.loaded / event.total * 100)}%
        `));
      }
    },
    onDownloadProgress: event => {
      if (event.loaded === event.total) {
        dispatch(updateExtractStatus(''));
      } else {
        dispatch(updateExtractStatus(`正在下载
          ${Math.round(event.loaded / event.total * 100)}%
        `));
      }
    },
  }).then(result => {
    const { extractedImage } = JSON.parse(result.data.data.extract).msg;
    dispatch(endExtractImage(extractedImage));
  });
};
