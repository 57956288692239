import { connect } from 'react-redux';

import * as imageActions from '../../actions/imageActions';
import IndexView from '../../components/capture/index';

const mapStateToProps = (state, ownProps) => ({
  extractedImage: state.image.extractedImage,
  extractStatus: state.image.extractStatus,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  extract: markedImage => { dispatch(imageActions.extractImage(markedImage)); },
});

const Index = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexView);

export default Index;
