import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    margin: '0 auto',
    padding: '0px 48px 48px',
    paddingTop: 24,
  },
  appBar: {
    position: 'fixed',
    top: 0,
    backgroundColor: '#20232a',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 56,
  },
  left: {
    marginRight: 'auto',
  },
  right: {
    marginLeft: 'auto',
  },
};

const Header = ({
  classes: {
    appBar,
    menu,
    left,
    right,
    logo,
  },
}) => (
  <AppBar className={appBar} position="static">
    <Toolbar className={menu}>
      <div className={left}>
        <Typography variant="h6" color="inherit" className={logo}>
          INVIMARC
        </Typography>
      </div>
      <div className={right}>
        <Button color="inherit" size="large" onClick={() => { navigate('/'); }}>首页</Button>
        <Button color="inherit" size="large" onClick={() => { window.open('http://invimarc.com', '_blank'); }}>关于我们</Button>
      </div>
    </Toolbar>
  </AppBar>
);

Header.propTypes = {
  classes: PropTypes.shape({
    appBar: PropTypes.shape({}).isRequired,
    menu: PropTypes.shape({}).isRequired,
    left: PropTypes.shape({}).isRequired,
    right: PropTypes.shape({}).isRequired,
    logo: PropTypes.shape({}).isRequired,
  }).isRequired,
};

Header.defaultProps = {
};

export default withStyles(styles)(Header);
