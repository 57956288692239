import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Index from '../containers/capture/index';

const CapturePage = () => (
  <Layout>
    <SEO title="InviMarc" />
    <Index />
  </Layout>
);

export default CapturePage;
