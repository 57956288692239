/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import CameraIcon from '@material-ui/icons/CameraAltOutlined';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  container: {
    marginTop: 0,
    paddingTop: 200,
    height: '100vh',
    width: '100vw',
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
  extractContainer: {
    width: '15rem',
  },
  extractImage: {
    width: '100%',
    marginBottom: -5,
  },
  progressContainer: {
    padding: 0,
  },
  extractProgress: {
    width: '100%',
  },
  extractStatus: {
    textAlign: 'center',
  },
  captureInput: {
    display: 'none',
  },
};

class Index extends React.PureComponent {
  render() {
    console.log('render');
    const {
      classes,
      extract,
      extractedImage,
      extractStatus,
    } = this.props;

    const onClickCapture = event => {
      const reader = new FileReader();
      reader.onloadend = () => {
        extract(reader.result.split(',').pop());
      };
      reader.readAsDataURL(event.target.files[0]);
    };

    console.log(extractedImage);
    if (!extractedImage) {
      return (
        <Container className={classes.container}>
          <input
            className={classes.captureInput}
            accept="image/*"
            id="capture"
            type="file"
            multiple="multiple"
            // capture="camera"
            onChange={onClickCapture}
          />
          <label htmlFor="capture">
            <Fab
              variant="contained"
              color="default"
              component="span"
              size="large"
              aria-label="Add"
            >
              <CameraIcon />
            </Fab>
          </label>
        </Container>
      );
    }

    return (
      <Container className={classes.container}>
        <Container className={classes.extractContainer}>
          <img
            className={classes.extractImage}
            src={`data:image/png;base64, ${extractedImage}`}
            alt=""
          />
          {extractStatus !== '' && (
            <Container className={classes.progressContainer}>
              <LinearProgress className={classes.extractProgress} />
              <div className={classes.extractStatus}>{extractStatus}</div>
            </Container>
          )}
        </Container>
      </Container>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.shape({}).isRequired,
    captureInput: PropTypes.shape({}).isRequired,
    extractContainer: PropTypes.shape({}).isRequired,
    extractImage: PropTypes.shape({}).isRequired,
    progressContainer: PropTypes.shape({}).isRequired,
    extractProgress: PropTypes.shape({}).isRequired,
    extractStatus: PropTypes.shape({}).isRequired,
  }).isRequired,
  extract: PropTypes.func.isRequired,
  extractedImage: PropTypes.string.isRequired,
  extractStatus: PropTypes.string.isRequired,
};

Index.defaultProps = {
};

export default withStyles(styles)(Index);
