import { Record } from 'immutable';
import * as imageActions from '../actions/imageActions';

const InitialState = new Record({
  imageOrigin: '',
  imageSecret: '',
  embeddedImage: '',
  extractedImage: '',
  extractStatus: '',
});

export default function reducer(state = new InitialState(), action) {
  switch (action.type) {
    case imageActions.IMAGE_ORIGIN_ADDED: {
      return state.set('imageOrigin', action.imageEncoded);
    }

    case imageActions.IMAGE_SECRET_ADDED: {
      return state.set('imageSecret', action.imageEncoded);
    }

    case imageActions.IMAGE_EMBED_END: {
      return state.set('embeddedImage', action.embeddedImage);
    }

    case imageActions.IMAGE_EXTRACT_START: {
      return state.set('extractedImage', action.markedImage);
    }

    case imageActions.IMAGE_EXTRACT_END: {
      return state.set('extractedImage', action.extractedImage);
    }

    case imageActions.IMAGE_EXTRACT_STATUS_UPDATE: {
      return state.set('extractStatus', action.status);
    }

    default:
  }
  return state;
}
